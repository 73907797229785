import React from 'react'
import { graphql, Link } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

const ArtPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Artworks" />
      <main className="container pt-12">
        <h1 className="text-xl lg:text-2xl mb-6 text-center">
          Original Artwork
        </h1>
        <p className="text-center mb-12">
          A commodo non quis ipsum ad quisque rutrum, conubia himenaeos lectus
          taciti porttitor venenatis. A commodo non quis ipsum ad quisque
          rutrum, conubia himenaeos lectus taciti porttitor venenatis. A commodo
          non quis ipsum ad quisque rutrum, conubia himenaeos lectus taciti
          porttitor venenatis. A commodo non quis ipsum ad quisque rutrum,
          conubia himenaeos lectus taciti porttitor venenatis.
        </p>
        <ul className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {data.allArtTaxonomyJson.nodes.map(({ name, slug, image }) => (
            <li className="text-center" key={slug}>
              <Link
                aria-label={name}
                title={`View all ${name} entries`}
                to={`/portfolio/${slug}`}
              >
                <Image alt="" fluid={image.childImageSharp.fluid} />
              </Link>

              <h2 className="mt-4 text-base md:text-lg mb-2 font-normal">
                <Link to={`/portfolio/${slug}`}>{name}</Link>
              </h2>
            </li>
          ))}
        </ul>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    allArtTaxonomyJson {
      nodes {
        name
        slug
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default ArtPage
